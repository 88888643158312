// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // DEV URL
  // url: 'https://uatapi.aiassist.ai',
  // UAT URL
  // url: 'https://canada.aiassist.ai',
  // LOCAL URL
  // url: 'http://localhost:8080',
  url: 'https://uk.aiassist.ai',
  // url: "https://australia.aiassist.ai",
  // UTC timezone
  // aus
  // timezone:'-1100',
  // US and Canada
  timezone:'-0400',
  // local timezone
  // timezone:'+0530',
  version:'v3.5.1'    
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
